import { apiUrl } from '../constants';
import { del } from './api-service';

export async function deleteBook(bookId: string) {
  try {
    await del({
      url: `${apiUrl}/book/${bookId}`,
    });
  } catch (err) {
    console.error(err);
  }
}
