import { Page } from '../types';
import { draw } from './draw';

export const TILE_SIZE = 32;

export const palette = [
  'white',
  'black',
  '#e91e1e',
  '#d55',
  'orange',
  '#ffce55',
  'yellow',
  'lightyellow',
  'green',
  'lightgreen',
  'blue',
  'lightblue',
  'purple',
  'rebeccapurple',
  'grey',
  'lightgrey',
];

export function getTileColour(tile: number): string {
  return palette[tile];
}

export function getForegroundColour(selected: number): string {
  const bg = getTileColour(selected);
  switch (bg) {
    case 'black':
    case '#e91e1e':
    case 'orange':
    case 'green':
    case 'blue':
    case 'purple':
    case 'rebeccapurple':
    case 'grey':
      return '#fafafa';

    case 'white':
    case '#d55':
    case '#ffce55':
    case 'yellow':
    case 'lightyellow':
    case 'lightgreen':
    case 'lightblue':
    case 'lightgrey':
    default:
      return '#363636';
  }
}

export function xy_index(x: number, y: number, width: number): number {
  return y * width + x;
}

export function clearMap(selected: number, page: Page) {
  const cleared = page.tiles.map(() => selected);
  const updated: Page = {
    ...page,

    tiles: cleared,
  };

  return updated;
}

export function updatePageHash(page: Page, canvas?: HTMLCanvasElement): Page {
  // allow an existing canvas to be passed
  const CANVAS = canvas ? canvas : document.createElement('canvas');
  CANVAS.width = page.width * TILE_SIZE;
  CANVAS.height = page.height * TILE_SIZE;

  draw(CANVAS, page.tiles, page.width, page.height, TILE_SIZE);
  const hash = CANVAS.toDataURL();
  // only remove canvas element if it was created in this fn
  if (!canvas) {
    CANVAS.remove();
  }

  return {
    ...page,
    hash,
  };
}

export function getPageArrayBuffer(
  page: Page,
  canvas?: HTMLCanvasElement,
): ArrayBuffer {
  const CANVAS = canvas ? canvas : document.createElement('canvas');
  CANVAS.width = page.width * TILE_SIZE;
  CANVAS.height = page.height * TILE_SIZE;
  const ctx = CANVAS.getContext('2d');

  draw(CANVAS, page.tiles, page.width, page.height, TILE_SIZE);
  const imgData = ctx?.getImageData(0, 0, 576, 576);

  // only remove canvas element if it was created in this fn
  if (!canvas) {
    CANVAS.remove();
  }

  return imgData?.data.buffer ?? new ArrayBuffer(0);
}

export function cloneArr<T>(arr: Array<T>): Array<T> {
  return arr.slice(0, arr.length);
}
