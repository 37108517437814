import { PropsWithChildren } from 'react';
import { PrimaryButton, SecondaryButton } from '../Button';
import { TabsWrapper } from './Tabs.styled';
import { DrawIcon } from '../icons/Draw';
import { PreviewIcon } from '../icons/Preview';

const TabButton = ({
  isActive,
  onClick,
  children,
}: PropsWithChildren<{ isActive: boolean; onClick: () => void }>) => {
  const ButtonComponent = isActive ? PrimaryButton : SecondaryButton;
  return <ButtonComponent onClick={onClick}>{children}</ButtonComponent>;
};

type Tab = 'edit' | 'preview';

export const Tabs = ({
  onChange,
  activeTab,
}: {
  onChange: (tab: Tab) => void;
  activeTab: Tab;
}) => {
  return (
    <TabsWrapper>
      <TabButton
        isActive={activeTab === 'edit'}
        onClick={() => onChange('edit')}
      >
        <DrawIcon /> Edit
      </TabButton>
      <TabButton
        isActive={activeTab === 'preview'}
        onClick={() => onChange('preview')}
      >
        <PreviewIcon /> Preview
      </TabButton>
    </TabsWrapper>
  );
};
