import { ChangeEvent, useState } from 'react';
import { Book } from '../../types';
import { Link } from 'react-router-dom';
import { PageLayout } from '../../components/PageLayout';
import { Navigation } from '../../components/Navigation';
import {
  DisabledButton,
  PrimaryButton,
  SecondaryButton,
} from '../../components/Button';
import {
  BookList,
  DashboardLink,
  DashboardPreview,
  EditBookControls,
  EditBookName,
  EditError,
  EditNameButton,
  EditWrapper,
  StyledBook,
} from './Dashboard.styled';
import { DeleteBookButton } from './DeleteBook';
import {
  addBook as addBookAction,
  editBook,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import NewIcon from '../../components/icons/New.gif';
import { DrawIcon } from '../../components/icons/Draw';
import { canAddBook, getUserLevel } from '../../utils/getUserLevel';
import { Tooltip } from 'react-tooltip';
import { UpgradeTooltip } from '../../components/UpgradeTooltip';

export const Dashboard = () => {
  const booksRedux: Book[] = useAppSelector((state) => state.book.books);

  return <DashboardWithData data={booksRedux} />;
};

export const DashboardWithData = ({ data }: { data: Book[] }) => {
  const [bookName, setBookName] = useState('');
  const [editing, setEditing] = useState('');
  const [hasError, setHasError] = useState(false);
  const dispatch = useAppDispatch();

  const addBook = () => {
    dispatch(addBookAction());
  };

  const editName = (book: Book) => {
    setEditing(book.id);
    setBookName(book.name);
  };

  const handleEdit = (e: ChangeEvent<HTMLInputElement>) => {
    setBookName(e.target.value);
    setHasError(false);
  };

  const finishEditing = (bookId: string) => {
    if (bookName === '') {
      setHasError(true);
      return;
    }

    dispatch(editBook({ id: bookId, name: bookName }));
    setEditing('');
    setBookName('');
    setHasError(false);
  };

  const cancelEdit = () => {
    setEditing('');
    setHasError(false);
    setBookName('');
  };

  const userLevel = getUserLevel();
  const userCanAddBook = canAddBook(data);

  return (
    <PageLayout>
      <Navigation />
      <h1>Dashboard</h1>
      {data.length ? (
        <BookList data-testid="books">
          {data.map((book) => (
            <StyledBook key={book.id}>
              {editing === book.id ? (
                <>
                  <EditWrapper>
                    <DashboardPreview
                      src={
                        book.pages && book.pages.length
                          ? book.pages[0].hash
                          : NewIcon
                      }
                    />
                    <EditBookName
                      data-testid="edit-book"
                      type="text"
                      value={bookName}
                      onChange={handleEdit}
                      placeholder="Book name"
                    />
                    {hasError ? (
                      <EditError>Name must not be blank</EditError>
                    ) : null}
                  </EditWrapper>
                  <EditBookControls>
                    <PrimaryButton onClick={() => finishEditing(book.id)}>
                      Save
                    </PrimaryButton>
                    <SecondaryButton onClick={cancelEdit}>
                      Cancel
                    </SecondaryButton>
                  </EditBookControls>
                </>
              ) : (
                <>
                  <DashboardLink to={`/edit/${book.id}`}>
                    <DashboardPreview
                      src={
                        book.pages && book.pages.length
                          ? book.pages[0].hash
                          : NewIcon
                      }
                    />
                    {book.name}
                  </DashboardLink>{' '}
                  <EditNameButton onClick={() => editName(book)}>
                    <DrawIcon /> Edit name
                  </EditNameButton>
                  <DeleteBookButton bookId={book.id} />
                </>
              )}
            </StyledBook>
          ))}
        </BookList>
      ) : (
        <p>No books</p>
      )}
      {userCanAddBook ? (
        <PrimaryButton onClick={addBook}>Add new</PrimaryButton>
      ) : (
        <>
          <UpgradeTooltip entity="book" />
          <DisabledButton className="limit-reached">Add new</DisabledButton>
          <p>
            You have reached the maximum number of books for a {userLevel}{' '}
            account.
          </p>
          {userLevel === 'standard' && (
            <p>
              <Link to="/account">Upgrade to add more.</Link>
            </p>
          )}
        </>
      )}
    </PageLayout>
  );
};
