import { PrimaryButton } from '../Button';
import { StyledSave, UnsavedChanges } from './SaveBar.styled';
import {
  saveBook,
  selectActiveBook,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { useSelector } from 'react-redux';
import { SaveIcon } from '../icons/Save';

export const SaveBar = () => {
  const activeBook = useSelector(selectActiveBook);

  const hasUnsavedChanges = useAppSelector(
    (state) => state.book.hasUnsavedChanges,
  );
  const pageNumber = useAppSelector((state) => state.book.pageNumber);
  const error = useAppSelector((state) => state.book.saveError);
  const dispatch = useAppDispatch();

  const handleSave = () => {
    dispatch(saveBook({ book: activeBook }));
  };

  return (
    <StyledSave data-testid="save-bar">
      {error && (
        <p className="error">Error saving changes. Please try again.</p>
      )}
      <h3>{activeBook.name}</h3>
      <h2>Page {Number(pageNumber) + 1}</h2>
      <div>
        {hasUnsavedChanges && <UnsavedChanges>Unsaved changes</UnsavedChanges>}
        <PrimaryButton onClick={handleSave}>
          <SaveIcon /> Save
        </PrimaryButton>
      </div>
    </StyledSave>
  );
};
