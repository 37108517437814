import { useRouteError } from 'react-router';
import { Link } from 'react-router-dom';

export const ErrorPage = () => {
  const error = useRouteError();

  console.error(error);
  return (
    <div>
      <h1>Page not found</h1>
      <p>
        Sorry, this page doesn't exist. Please check the url or{' '}
        <Link to={'/'}>visit the homepage</Link>.
      </p>
    </div>
  );
};
