import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { theme } from '../utils/theme';

const StyledNav = styled.nav`
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
`;

const StyledNavList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: inline-block;
    padding-left: 1rem;
    padding-right: 1rem;
    border-left: 1px solid ${theme.colour.primaryMuted};
  }
  li:first-child {
    border: none;
  }
`;

const LogoutButton = styled.button`
  background: none;
  border: none;
`;

export const Navigation = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    navigate('/login');
  };

  return (
    <StyledNav data-testid="nav">
      <StyledNavList>
        <li>
          <NavLink to="/dashboard">Dashboard</NavLink>
        </li>
        <li>
          <NavLink to="/account">Account</NavLink>
        </li>
        <li>
          <LogoutButton as={NavLink} to="#" onClick={handleLogout}>
            Logout
          </LogoutButton>
        </li>
      </StyledNavList>
    </StyledNav>
  );
};
