import { DeleteButton } from './Dashboard.styled';
import { DeleteIcon } from '../../components/icons/Delete';
import { deleteBookAction, useAppDispatch } from '../../store';

export const DeleteBookButton = ({ bookId }: { bookId: string }) => {
  const dispatch = useAppDispatch();
  const remove = async () => {
    dispatch(deleteBookAction({ id: bookId }));
  };

  const handleClick = () => {
    if (window.confirm('Delete book?')) {
      remove();
    }
  };

  return (
    <DeleteButton onClick={handleClick}>
      <DeleteIcon /> Delete
    </DeleteButton>
  );
};
