export const theme = {
  colour: {
    background: '#f9f9f9',
    primary: '#ffb500',
    primaryMuted: '#c48300',
    border: '#eea900',
    error: '#f080803b',
    errorStrong: '#f08080',
    errorMuted: 'lightcoral',
  },
  borderRadius: '0.25rem',
  maxWidth: '800px',
};
