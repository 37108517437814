import { FormEvent, useState } from 'react';
import { Navigation } from '../../components/Navigation';
import { PageLayout } from '../../components/PageLayout';
import {
  FormError,
  FormNotification,
  FormRow,
  Input,
  Label,
} from '../Login/Login.styled';
import { post } from '../../api/api-service';
import { PrimaryButton } from '../../components/Button';
import { apiUrl } from '../../constants';
import { AxiosError } from 'axios';
import { getUserLevel } from '../../utils/getUserLevel';
import { Tooltip } from 'react-tooltip';

export const Account = () => {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState(false);

  async function handlePasswordChange(e: FormEvent) {
    e.preventDefault();
    setError(null);
    setSuccess(false);

    if (newPassword !== confirmNewPassword) {
      setError('Passwords must match');
      return;
    }

    const body = new URLSearchParams({
      current_password: currentPassword,
      new_password: newPassword,
      confirm_new_password: confirmNewPassword,
    });

    try {
      const _response = await post({
        url: `${apiUrl}/admin/password`,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body,
      });
      setSuccess(true);
    } catch (err) {
      const error = err as AxiosError;
      console.log('err', error.response?.data);
      setError(error.response?.data as string);
    }
  }

  const handleAccountLevelChange = (e: FormEvent) => {
    e.preventDefault();
    console.log('Upgrading account');
  };
  const userLevel = getUserLevel();

  return (
    <PageLayout>
      <Navigation />

      <h1>Account settings</h1>

      <form onSubmit={handleAccountLevelChange}>
        <p>You have a {userLevel} account.</p>
        <p>
          Standard accounts can have up to 3 books with a maximum of 10 pages in
          each.
        </p>
        <p>
          Premium accounts can have up to 100 books with a maximum of 100 pages
          in each.
        </p>
        {userLevel === 'standard' && (
          <>
            <Tooltip id="todo" />
            <PrimaryButton
              type="submit"
              data-tooltip-id="todo"
              data-tooltip-content={'Not yet implemented'}
            >
              Upgrade
            </PrimaryButton>
          </>
        )}
      </form>

      <form onSubmit={handlePasswordChange}>
        <h2>Change password</h2>
        {error && <FormError>Something went wrong: {error}</FormError>}
        {success && <FormNotification>Password updated</FormNotification>}

        <Label htmlFor="currentPassword">Current password</Label>
        <FormRow>
          <Input
            type="password"
            name="currentPassword"
            id="currentPassword"
            placeholder="Password"
            required
            onChange={(e) => setCurrentPassword(e.target.value)}
          />
        </FormRow>

        <Label htmlFor="newPassword">New password</Label>
        <FormRow>
          <Input
            type="password"
            name="newPassword"
            id="newPassword"
            placeholder="New password"
            required
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormRow>

        <Label htmlFor="confirmNewPassword">Confirm new password</Label>
        <FormRow>
          <Input
            type="password"
            name="confirmNewPassword"
            id="confirmNewPassword"
            placeholder="Confirm new password"
            required
            onChange={(e) => setConfirmNewPassword(e.target.value)}
          />
        </FormRow>
        <PrimaryButton type="submit">Update password</PrimaryButton>
      </form>
    </PageLayout>
  );
};
