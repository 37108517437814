import { defaultPage } from '../components/PageProvider';
import { Book, Page, RawBook } from '../types';
import { updatePageHash } from '../utils/utils';

// pages can be null from server
export const mapBook = (book: RawBook): Book => {
  const CANVAS = document.createElement('canvas');
  const { updated_at, ...rest } = book;
  const updatedBook = {
    ...rest,
    updated: new Date(updated_at).toISOString(),
    pages: book.pages
      ? book.pages
            .map(({ page_order, ...rest }) => ({
              ...rest,
              pageNumber: page_order,
            }))
            .sort((a, b) => a.pageNumber - b.pageNumber)
            .map((page) => {
              // generate preview on client
              return updatePageHash(page as Page, CANVAS);
            })
        
      : [defaultPage],
  };

  CANVAS.remove();

  return updatedBook;
};
