import { jwtDecode, JwtPayload } from 'jwt-decode';
import { Book } from '../types';

type Token = JwtPayload & { roles: string[] };
export type UserLevel = 'standard' | 'premium' | 'admin';

// only use for rendering
// levels checked server side
export function getUserLevel(): UserLevel {
  const token = sessionStorage.getItem('token');
  if (!token) {
    return 'standard';
  }
  const decoded = jwtDecode<Token>(token);
  return (decoded.roles[0].toLowerCase() as UserLevel) ?? 'standard';
}

export function canAddPage(book: Book): boolean {
  const level = getUserLevel();
  const numPages = book.pages ? book.pages.length : 0;
  return (
    (level === 'standard' && numPages < 3) ||
    (level !== 'standard' && numPages < 100)
  );
}

export function canAddBook(books: Book[]): boolean {
  const userLevel = getUserLevel();
  return (
    (userLevel === 'standard' && books.length < 3) ||
    (userLevel !== 'standard' && books.length < 100)
  );
}
