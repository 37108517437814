import styled from 'styled-components';
import { Card } from '../Card';
import { PrimaryButton } from '../Button';

export const StyledToolbar = styled.ul`
  max-width: 180px;
  margin: 0 0 0.5rem;
  padding: 0;
  list-style-type: none;
`;

export const ToolbarWrapper = styled(Card)`
  svg {
    max-width: 1.25rem;
    max-height: 1.25rem;
  }
`;

export const BrushBlock = styled.span`
  display: block;
  width: 40px;
  height: 40px;
  margin: 0.25rem;
`;

export const Brush = styled.button`
  display: inline-block;
  margin: 0;
  cursor: pointer;
  border: 3px solid transparent;

  &:hover {
    border: 3px solid red;
  }
`;

export const SelectedBrush = styled(Brush)`
  border: 3px solid red;
`;

export const ToolWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0.25rem;
  margin-bottom: 1.5rem;
`;

export const ActiveTool = styled(PrimaryButton)`
  border: 3px solid red;
`;
