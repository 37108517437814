import styled from 'styled-components';
import { theme } from '../utils/theme';

export const PrimaryButton = styled.button`
  border: none;
  background-color: ${theme.colour.primary};
  font-size: 1rem;
  border-radius: ${theme.borderRadius};
  padding: 0.5rem 1rem;
  cursor: pointer;
  border-bottom: 3px solid ${theme.colour.border};

  &:hover {
    background-color: #ffc433;
  }

  svg {
    max-width: 1.25rem;
    max-height: 1.25rem;
  }
`;

export const SecondaryButton = styled(PrimaryButton)`
  background-color: #fff;

  &:hover {
    background-color: ${theme.colour.primary};
  }
`;

export const DestructiveButton = styled(PrimaryButton)`
  border-color: ${theme.colour.error};
  background-color: ${theme.colour.errorMuted};

  &:hover {
    background-color: ${theme.colour.error};
  }
`;

export const DisabledButton = styled(SecondaryButton)`
  background-color: lightgrey;
  color: grey;
  border-color: lightgrey;

  &:hover {
    background-color: lightgrey;
  }
`;
