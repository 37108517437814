import styled from 'styled-components';
import { DestructiveButton, PrimaryButton } from '../../components/Button';
import { Link } from 'react-router-dom';

export const BookList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
  margin-bottom: 2rem;
  max-width: 750px;
`;

export const StyledBook = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
`;
export const DashboardLink = styled(Link)`
  display: flex;
  align-items: center;
  width: 100%;

  &:hover {
    background: #ffb5001c;
  }
`;

export const DashboardPreview = styled.img`
  width: 100px;
  height: 100px;
  margin-right: 1rem;
`;

export const EditWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 2;
`;

export const EditBookName = styled.input`
  padding: 0.25rem 0.5rem;
  font-size: 1rem;
`;

export const EditBookControls = styled.div`
  align-self: flex-start;

  button {
    margin-left: 0.5rem;
  }
`;

export const EditError = styled.span`
  color: #ea1818;
  display: block;
`;

export const EditNameButton = styled(PrimaryButton)`
  flex-shrink: 0;
  width: 130px;
  display: flex;
  justify-content: space-between;
  margin-right: 0.5rem;
`;

export const DeleteButton = styled(DestructiveButton)`
  flex-shrink: 0;
  width: 100px;
  display: flex;
  justify-content: space-between;
`;
