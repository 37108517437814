import { DestructiveButton } from '../Button';
import { deletePage } from '../../api/deletePage';
import {
  changePageNumber,
  selectActiveBook,
  updateBook,
  useAppDispatch,
} from '../../store';
import { useSelector } from 'react-redux';
import { cloneArr } from '../../utils/utils';
import { DeleteIcon } from '../icons/Delete';

export const DeleteButton = ({ pageNumber }: { pageNumber: number }) => {
  const book = useSelector(selectActiveBook);
  const dispatch = useAppDispatch();

  const remove = async () => {
    if (book.pages) {
      const updatedPages = cloneArr(book.pages);
      let removed = updatedPages.splice(pageNumber, 1);
      dispatch(updateBook({ ...book, pages: updatedPages }));
      dispatch(changePageNumber(0));

      // TODO move to store
      // call api
      deletePage(removed[0].id);
    }
  };

  const handleClick = () => {
    if (window.confirm('Delete page?')) {
      remove();
    }
  };

  return (
    <DestructiveButton onClick={handleClick}>
      <DeleteIcon /> Delete
    </DestructiveButton>
  );
};
