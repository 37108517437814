import axios, { AxiosError, AxiosRequestConfig } from 'axios';

type Options = {
  url: string;
  method: string;
  headers?: Record<string, string>;
  body?: Record<string, any>;
};

async function request<TData>({
  url,
  method,
  headers,
  body,
  ...rest
}: Options): Promise<TData> {
  const token = sessionStorage.getItem('token');
  if (!token) {
    // TODO
    console.error('Not logged in');
    window.location.href = new URL('/login', window.location.origin).toString();
  }

  try {
    const options: AxiosRequestConfig = {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        ...headers,
      },
      method,
    };
    if (body) {
      options.data = body;
    }
    const response = await axios(url, options);

    return response.data;
  } catch (err) {
    const error = err as AxiosError;
    if (error.response) {
      // TODO check response status, could be legit
    } else if (error.request) {
      // request failed, likely auth/cors
      if (error.request.status === 0) {
        window.location.href = '/login';
      }
    }

    return Promise.reject(err);
  }
}

function get<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'GET',
    ...options,
  });
}

function post<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'POST',
    ...options,
  });
}

function put<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'PUT',
    ...options,
  });
}

function patch<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'PATCH',
    ...options,
  });
}

function del<TData>(options: Omit<Options, 'method'>) {
  return request<TData>({
    method: 'DELETE',
    ...options,
  });
}
export { get, post, put, patch, del };
