import { defaultPage } from '../PageProvider';
import { StyledAddButton } from './AddButton.styled';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import {
  changePageNumber,
  selectActiveBook,
  updateBook,
  useAppDispatch,
} from '../../store';
import { cloneArr } from '../../utils/utils';
import { AddIcon } from '../icons/Add';

export const AddButton = () => {
  const book = useSelector(selectActiveBook);
  const dispatch = useAppDispatch();

  const addPage = () => {
    if (!book.pages) {
      book.pages = [];
    }

    // TODO move to store
    const newPageNumber = book.pages.length;
    const newPage = {
      ...defaultPage,
      tiles: Array(defaultPage.width * defaultPage.height).fill(0),
      id: uuid(),
      pageNumber: book.pages.length,
    };

    // use new object so state updates
    if (book && book.pages) {
      const updatedPages = cloneArr(book.pages);
      updatedPages.push(newPage);
      dispatch(updateBook({ ...book, pages: updatedPages }));
      dispatch(changePageNumber(newPageNumber));
    }
  };

  return (
    <StyledAddButton onClick={addPage} data-testid="add-page">
      <AddIcon /> Add
    </StyledAddButton>
  );
};
