import { AddButton } from './AddButton';
import { DeleteButton } from './DeleteButton';
import { LoadButton } from './LoadButton';
import { DuplicateButton } from './DuplicateButton';
import { LevelControls, StyledItem, StyledList } from './LevelList.styled';
import { selectActiveBook, useAppSelector } from '../../store';
import { useSelector } from 'react-redux';
import { canAddPage } from '../../utils/getUserLevel';
import { DisabledButton } from '../Button';
import { AddIcon } from '../icons/Add';
import { UpgradeTooltip } from '../UpgradeTooltip';

export const LevelList = () => {
  const activeBook = useSelector(selectActiveBook);
  const pageNumber = useAppSelector((state) => state.book.pageNumber);
  const userCanAddPages = canAddPage(activeBook);

  return (
    <StyledList className="level-list" data-testid="pages">
      {userCanAddPages || <UpgradeTooltip entity="page" />}
      {activeBook.pages?.map((page, i) => (
        <StyledItem key={`level-button-${i}`}>
          <LoadButton
            preview={page.hash}
            pageNumber={i}
            selected={i === pageNumber}
          />
          <LevelControls>
            {userCanAddPages ? (
              <DuplicateButton pageNumber={i} />
            ) : (
              <DisabledButton className="limit-reached">Copy</DisabledButton>
            )}
            <DeleteButton pageNumber={i} />
          </LevelControls>
        </StyledItem>
      ))}

      {userCanAddPages ? (
        <AddButton />
      ) : (
        <>
          <DisabledButton className="limit-reached">
            <AddIcon />
            Add
          </DisabledButton>
          <p>Page limit reached.</p>
        </>
      )}
    </StyledList>
  );
};
