import { useEffect, useState } from 'react';
import { LevelList } from '../../components/Level/LevelList';
import { Map } from '../../components/Map/Map';
import { SaveBar } from '../../components/SaveBar/SaveBar';
import { Toolbar } from '../../components/Toolbar/Toolbar';
import { ToolbarProvider } from '../../components/Toolbar/ToolbarProvider';
import { Animation } from '../../components/Animation/Animation';
import { useParams } from 'react-router';
import { Navigation } from '../../components/Navigation';
import {
  EditContent,
  NavWrapper,
  StyledEdit,
  StyledWrapper,
} from './Edit.styled';
import { Tabs } from '../../components/Tabs/Tabs';
import { selectBook, useAppDispatch } from '../../store';

function EditPage() {
  const { bookName } = useParams();
  const [activeTab, setActiveTab] = useState<'edit' | 'preview'>('edit');
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(selectBook(bookName));
  }, [bookName, dispatch]);

  return (
    <StyledEdit>
      <NavWrapper>
        <Navigation />
      </NavWrapper>

      <Tabs onChange={setActiveTab} activeTab={activeTab} />
      {activeTab === 'edit' ? (
        <>
          <StyledWrapper>
            <Toolbar />
            <EditContent>
              <SaveBar />
              <Map />
            </EditContent>
          </StyledWrapper>
          <LevelList />
        </>
      ) : (
        <Animation />
      )}
    </StyledEdit>
  );
}

const Wrapper = () => (
  <ToolbarProvider>
    <EditPage />
  </ToolbarProvider>
);

export default Wrapper;
