import { PropsWithChildren, createContext, useState } from 'react';
import { initialState } from '../../store';

type DrawMode = 'draw' | 'fill';

type ToolbarContextType = {
  selected: number;
  setSelected: (selected: number) => void;
  mode: DrawMode;
  setMode: (mode: DrawMode) => void;
};

const defaultSelected = 0;

export const ToolbarContext = createContext<ToolbarContextType>({
  selected: defaultSelected,
  setSelected: () => {},
  mode: 'draw',
  setMode: () => {},
});

export const ToolbarProvider = ({
  children,
  initialValue = defaultSelected,
}: PropsWithChildren<{ initialValue?: number }>) => {
  const [selected, setSelected] = useState(initialValue);
  const [mode, setMode] = useState<DrawMode>('draw');

  return (
    <ToolbarContext.Provider value={{ selected, setSelected, mode, setMode }}>
      {children}
    </ToolbarContext.Provider>
  );
};
