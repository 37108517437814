import { PropsWithChildren } from 'react';
import { DisabledButton, SecondaryButton } from '../Button';

export const HistoryButton = ({
  disabled,
  onClick,
  children,
}: PropsWithChildren<{ disabled: boolean; onClick: () => void }>) => {
  const ButtonComponent = disabled ? DisabledButton : SecondaryButton;
  return (
    <ButtonComponent disabled={disabled} onClick={onClick}>
      {children}
    </ButtonComponent>
  );
};
