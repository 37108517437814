import { SecondaryButton } from '../Button';
import { v4 as uuid } from 'uuid';
import { useSelector } from 'react-redux';
import { selectActiveBook, updateBook, useAppDispatch } from '../../store';
import { cloneArr } from '../../utils/utils';
import { CopyIcon } from '../icons/Copy';

export const DuplicateButton = ({ pageNumber }: { pageNumber: number }) => {
  const book = useSelector(selectActiveBook);
  const dispatch = useAppDispatch();

  const duplicatePage = () => {
    if (book.pages) {
      const cloned = JSON.parse(JSON.stringify(book.pages[pageNumber]));
      cloned.id = uuid();
      cloned.pageNumber = cloned.pageNumber + 1;

      if (book && book.pages) {
        const updatedPages = cloneArr(book.pages);
        updatedPages.splice(pageNumber + 1, 0, cloned);
        dispatch(updateBook({ ...book, pages: updatedPages }));
      }
    }
  };

  return (
    <SecondaryButton onClick={duplicatePage} className="button">
      <CopyIcon /> Copy
    </SecondaryButton>
  );
};
