import { MouseEvent } from 'react';

import { getTileColour, TILE_SIZE, xy_index } from './utils';

export type Rect = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export function draw(
  canvas: HTMLCanvasElement,
  tiles: number[],
  width: number,
  height: number,
  tileSize = TILE_SIZE,
) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }

  ctx.clearRect(0, 0, width * tileSize, height * tileSize);
  drawTiles(ctx, tiles, width, height, tileSize);
}

export function drawTiles(
  ctx: CanvasRenderingContext2D,
  tiles: number[],
  width: number,
  height: number,
  tileSize: number,
) {
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      drawRect(
        ctx,
        x * tileSize,
        y * tileSize,
        tiles[xy_index(x, y, width)],
        tileSize,
      );
    }
  }
}

function drawRect(
  ctx: CanvasRenderingContext2D,
  x: number,
  y: number,
  tile: number,
  tileSize: number,
) {
  ctx.fillStyle = getTileColour(tile);
  ctx.fillRect(x, y, tileSize, tileSize);
}

export function drawMouse(
  canvas: HTMLCanvasElement,
  x: number,
  y: number,
  selectedBrush: number,
) {
  const ctx = canvas.getContext('2d');
  if (!ctx) {
    return;
  }
  ctx.fillStyle = getTileColour(selectedBrush);
  ctx.fillRect(x, y, TILE_SIZE, TILE_SIZE);
  ctx.strokeStyle = 'purple';
  ctx.strokeRect(x, y, TILE_SIZE, TILE_SIZE);
}

export function updateMap(
  canvas: HTMLCanvasElement | null,
  e: MouseEvent,
  points: Rect[],
  tiles: number[],
  width: number,
  selectedBrush: number,
): number[] {
  if (!canvas) {
    return tiles;
  }

  const bounds = canvas.getBoundingClientRect();
  const x = e.clientX - bounds.left;
  const y = e.clientY - bounds.top;

  for (let i = 0; i < points.length; i++) {
    const rect = points[i];
    if (
      x >= rect.x &&
      x <= rect.x + rect.width &&
      y >= rect.y &&
      y <= rect.y + rect.height
    ) {
      // inside this rect

      tiles[xy_index(rect.x / TILE_SIZE, rect.y / TILE_SIZE, width)] =
        selectedBrush;
      return tiles;
    }
  }
  return tiles;

  // don't bother re-drawing since they'll have to move the mouse away anyway
}

// create a lookup table of rectangles in the canvas
export function generatePoints(width: number, height: number): Rect[] {
  const points = [];
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      points.push({
        x: x * TILE_SIZE,
        y: y * TILE_SIZE,
        width: TILE_SIZE,
        height: TILE_SIZE,
      });
    }
  }
  return points;
}

export function onMouseMove(
  canvas: HTMLCanvasElement | null,
  e: MouseEvent,
  points: Rect[],
  tiles: number[],
  width: number,
  height: number,
  selectedBrush: number,
) {
  if (!canvas) {
    return;
  }
  draw(canvas, tiles, width, height);

  const bounds = canvas.getBoundingClientRect();
  const x = e.clientX - bounds.left;
  const y = e.clientY - bounds.top;

  for (let i = 0; i < points.length; i++) {
    const rect = points[i];
    if (
      x >= rect.x &&
      x <= rect.x + rect.width &&
      y >= rect.y &&
      y <= rect.y + rect.height
    ) {
      // inside this rect
      drawMouse(canvas, rect.x, rect.y, selectedBrush);
      break;
    }
  }
}
