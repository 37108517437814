import { FormEvent, useState } from 'react';
import { apiUrl } from '../../constants';
import { useNavigate } from 'react-router';

import { PageLayout } from '../../components/PageLayout';
import { PrimaryButton, SecondaryButton } from '../../components/Button';
import { FormError, FormRow, Input, Label } from './Login.styled';

export const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [hasError, setHasError] = useState(false);

  async function handleLogin(e: FormEvent) {
    e.preventDefault();
    setHasError(false);
    const body = new URLSearchParams({
      username,
      password,
    });

    try {
      const response = await fetch(`${apiUrl}/login`, {
        method: 'POST',
        body,
      });
      const token = await response.json();
      // TODO cookies
      sessionStorage.setItem('token', token);
      navigate('/dashboard');
    } catch (err) {
      setHasError(true);
      console.error('login failed');
    }
  }

  return (
    <PageLayout>
      <h1>Log in</h1>
      {hasError && (
        <FormError>
          There was a problem logging in. Check your username and password and
          try again.
        </FormError>
      )}
      <form onSubmit={handleLogin}>
        <Label htmlFor="username">Username </Label>
        <FormRow>
          <Input
            name="username"
            placeholder="Username"
            required
            onChange={(e) => setUsername(e.target.value)}
          />
        </FormRow>

        <Label htmlFor="password">Password </Label>
        <FormRow>
          <Input
            type={showPassword ? 'text' : 'password'}
            name="password"
            placeholder="Password"
            required
            onChange={(e) => setPassword(e.target.value)}
          />
          <SecondaryButton
            type="button"
            onClick={() => setShowPassword(!showPassword)}
          >
            {showPassword ? 'Hide' : 'Show'}
          </SecondaryButton>
        </FormRow>

        <PrimaryButton>Login</PrimaryButton>
      </form>
    </PageLayout>
  );
};
