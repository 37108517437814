import { v4 as uuid } from 'uuid';
import { Page } from '../types';
import { updatePageHash } from '../utils/utils';

const defaultTiles = Array(18 * 18).fill(0);

export const defaultPage: Page = {
  id: uuid(),
  tiles: defaultTiles,
  width: 18,
  height: 18,
  pageNumber: 0,
  // @ts-ignore
  hash: updatePageHash({ width: 18, height: 18, tiles: defaultTiles }).hash,
};
