function isValid(
  screen: Array<Array<number>>,
  width: number,
  height: number,
  x: number,
  y: number,
  previousColour: number,
  newColour: number,
): boolean {
  if (
    x < 0 ||
    x >= width ||
    y < 0 ||
    y >= height ||
    screen[x][y] !== previousColour ||
    screen[x][y] === newColour
  ) {
    return false;
  }
  return true;
}

export function floodFill(
  screen: Array<Array<number>>,
  width: number,
  height: number,
  x: number,
  y: number,
  prevColour: number,
  newColour: number,
) {
  const queue = [];
  queue.push([x, y]);
  screen[x][y] = newColour;

  while (queue.length > 0) {
    const currentPixel: Array<number> = queue[queue.length - 1];
    queue.pop();
    const positionX = currentPixel[0];
    const positionY = currentPixel[1];

    if (
      isValid(
        screen,
        width,
        height,
        positionX + 1,
        positionY,
        prevColour,
        newColour,
      )
    ) {
      screen[positionX + 1][positionY] = newColour;
      queue.push([positionX + 1, positionY]);
    }

    if (
      isValid(
        screen,
        width,
        height,
        positionX - 1,
        positionY,
        prevColour,
        newColour,
      )
    ) {
      screen[positionX - 1][positionY] = newColour;
      queue.push([positionX - 1, positionY]);
    }

    if (
      isValid(
        screen,
        width,
        height,
        positionX,
        positionY + 1,
        prevColour,
        newColour,
      )
    ) {
      screen[positionX][positionY + 1] = newColour;
      queue.push([positionX, positionY + 1]);
    }

    if (
      isValid(
        screen,
        width,
        height,
        positionX,
        positionY - 1,
        prevColour,
        newColour,
      )
    ) {
      screen[positionX][positionY - 1] = newColour;
      queue.push([positionX, positionY - 1]);
    }
  }

  return screen;
}

export function chunkArray(
  tiles: Array<number>,
  width: number,
): Array<Array<number>> {
  const result = [];
  for (let i = 0; i < tiles.length; i += width) {
    result.push(tiles.slice(i, i + width));
  }

  return result;
}
