import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { getUserLevel } from '../utils/getUserLevel';
import styled from 'styled-components';

const StyledUpgradeLink = styled(Link)`
  color: white;
`;

export const UpgradeTooltip = ({ entity }: { entity: 'page' | 'book' }) => {
  const userLevel = getUserLevel();
  return (
    <Tooltip anchorSelect=".limit-reached" data-tooltip-place="top" clickable>
      {userLevel === 'standard' ? (
        <>
          <p>You've reached the {entity} limit for a standard account.</p>
          <p>
            <StyledUpgradeLink to="/account">
              Upgrade to allow more
            </StyledUpgradeLink>
          </p>
        </>
      ) : (
        <p>Page limit reached</p>
      )}
    </Tooltip>
  );
};
