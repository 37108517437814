import styled from 'styled-components';
import { PrimaryButton } from '../Button';

export const StyledAddButton = styled(PrimaryButton)`
  padding: 0 2.5rem;
  margin-left: 2rem;

  span {
    display: block;
    margin-bottom: 0.25rem;
    font-size: 3rem;
  }
`;
