import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import {
  draw,
  generatePoints,
  onMouseMove,
  Rect,
  updateMap,
} from '../../utils/draw';
import {
  cloneArr,
  TILE_SIZE,
  updatePageHash,
  xy_index,
} from '../../utils/utils';
import { ToolbarContext } from '../Toolbar/ToolbarProvider';
import { EditCanvas } from './Map.styled';
import { chunkArray, floodFill } from '../../utils/fill';
import { Page } from '../../types';
import {
  selectActiveBook,
  updateBook,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { useSelector } from 'react-redux';

// Not in state - don't re-render when flag changes
let isMouseDown = false;

function compareArrays<T>(first: Array<T>, second: Array<T>): boolean {
  if (first.length !== second.length) {
    return false;
  }

  return first.every((item, i) => second[i] === item);
}

export function Map() {
  const activeBook = useSelector(selectActiveBook);
  const page = useAppSelector((state) => state.book.present);
  const pageNumber = useAppSelector((state) => state.book.pageNumber);
  const dispatch = useAppDispatch();

  const { selected, mode } = useContext(ToolbarContext);
  const canvasRef = useRef<HTMLCanvasElement>(null);

  const hashCanvas = document.createElement('canvas');

  const points: Rect[] = useMemo(
    () => generatePoints(page.width, page.height),
    [page],
  );

  useEffect(() => {
    window.requestAnimationFrame(() => {
      if (canvasRef.current !== null) {
        draw(canvasRef.current, page.tiles, page.width, page.height);
      }
    });
  }, [canvasRef, page]);

  const handleMouseMove = useCallback(
    (e: MouseEvent) => {
      // if mouse down, draw
      if (isMouseDown) {
        // TODO optimise - don't draw same rect
        // TODO optimise - dispatches on every mouse move
        const updatedTiles = updateMap(
          canvasRef.current,
          e,
          points,
          cloneArr(page.tiles),
          page.width,
          selected,
        );

        // only update hash/store if the tiles have been changed, ie
        // not when mouse moves inside a square
        if (!compareArrays(page.tiles, updatedTiles)) {
          const updatedPage = updatePageHash(
            { ...page, tiles: updatedTiles },
            hashCanvas,
          );
          if (activeBook && activeBook.pages) {
            const updatedPages = cloneArr(activeBook.pages);
            updatedPages[pageNumber] = updatedPage;
            // console.log('dispatch mousemove');
            dispatch(updateBook({ ...activeBook, pages: updatedPages }));
          }
        }
      }

      window.requestAnimationFrame(() =>
        onMouseMove(
          canvasRef.current,
          e,
          points,
          page.tiles,
          page.width,
          page.height,
          selected,
        ),
      );
    },
    [points, page, selected],
  );

  const handleMouseUp = () => {
    isMouseDown = false;
  };

  const handleMouseLeave = useCallback(
    (_e: MouseEvent) => {
      isMouseDown = false;
      // redraw without mouse
      window.requestAnimationFrame(() =>
        draw(canvasRef.current!, page.tiles, page.width, page.height),
      );
    },
    [canvasRef, page],
  );

  // mutates page
  function floodFillMap(
    canvas: HTMLCanvasElement | null,
    e: MouseEvent,
    points: Rect[],
    page: Page,
    selected: number,
  ) {
    if (!canvas) {
      return page.tiles;
    }

    const bounds = canvas.getBoundingClientRect();
    const x = e.clientX - bounds.left;
    const y = e.clientY - bounds.top;

    for (let i = 0; i < points.length; i++) {
      const rect = points[i];
      if (
        x >= rect.x &&
        x <= rect.x + rect.width &&
        y >= rect.y &&
        y <= rect.y + rect.height
      ) {
        // inside this rect
        const previousColour =
          page.tiles[
            xy_index(rect.x / TILE_SIZE, rect.y / TILE_SIZE, page.width)
          ];
        const screen = floodFill(
          chunkArray(page.tiles, page.width),
          page.width,
          page.height,
          rect.y / TILE_SIZE, // invert x and y due to chunking
          rect.x / TILE_SIZE,
          previousColour,
          selected,
        );

        return screen.flat();
      }
    }

    // if no points to iterate
    return page.tiles;
  }

  const handleMouseClick = useCallback(
    (e: MouseEvent) => {
      isMouseDown = true;
      if (mode === 'fill') {
        const filledMap = floodFillMap(
          canvasRef.current,
          e,
          points,
          page,
          selected,
        );
        if (filledMap) {
          const updatedPage = updatePageHash(
            { ...page, tiles: filledMap },
            hashCanvas,
          );

          if (activeBook && activeBook.pages) {
            const updatedPages = cloneArr(activeBook.pages);
            updatedPages[pageNumber] = updatedPage;
            // console.log('dispatch fill');
            dispatch(updateBook({ ...activeBook, pages: updatedPages }));
          }
        }
      } else {
        const updatedTiles = updateMap(
          canvasRef.current,
          e,
          points,
          // clone tiles since it's from Redux
          page.tiles.slice(0, page.tiles.length),
          page.width,
          selected,
        );

        const updatedPage = updatePageHash(
          { ...page, tiles: updatedTiles },
          hashCanvas,
        );

        if (activeBook && activeBook.pages) {
          const updatedPages = cloneArr(activeBook.pages);
          updatedPages[pageNumber] = updatedPage;
          // console.log('dispatch click');
          dispatch(updateBook({ ...activeBook, pages: updatedPages }));
        }
      }
    },
    [
      mode,
      points,
      page,
      selected,
      activeBook,
      updateBook,
      pageNumber,
      hashCanvas,
    ],
  );

  // TODO use offscreen canvas https://developer.mozilla.org/en-US/docs/Web/API/OffscreenCanvas#instance_methods
  // - draw all tiles offscreen
  // - blit to onscreen + draw mouse
  // - use offscreen for hash
  // - use offscreen as source to resize for preview
  return (
    <EditCanvas
      className="canvas"
      data-testid="edit-canvas"
      ref={canvasRef}
      width={page.width * TILE_SIZE}
      height={page.height * TILE_SIZE}
      onMouseMove={handleMouseMove}
      onMouseDown={handleMouseClick}
      onMouseLeave={handleMouseLeave}
      onMouseUp={handleMouseUp}
    />
  );
}
