import { encode } from 'modern-gif';
import { Page } from '../types';
import { getPageArrayBuffer } from './utils';

const IMG_WIDTH = 576;
const IMG_HEIGHT = 576;

export async function createGif(rawFrames: Page[], delay = 250) {
  const frames = rawFrames.map((page) => ({
    data: getPageArrayBuffer(page),
    delay,
  }));
  const output = await encode({
    width: IMG_WIDTH,
    height: IMG_HEIGHT,
    frames: frames,
  });

  const blob = new Blob([output], { type: 'image/gif' });
  window.open(URL.createObjectURL(blob));
}
