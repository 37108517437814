import { getAllBooks } from '../api/getAllBooks';
import { Book, Page } from '../types';
import { addNewBook } from '../api/newBook';
import { defaultBook } from '../components/BookProvider';
import { editBookName } from '../api/editBookName';
import { updateBook as updateBookApi } from '../api/updateBook';
import { deleteBook } from '../api/deleteBook';
import { defaultPage } from '../components/PageProvider';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from './store';

export const loadBooks = createAsyncThunk('loadBooks', async () => {
  const books = await getAllBooks();
  return books;
});

export const addBook = createAsyncThunk('addBook', async () => {
  const id = await addNewBook();
  return id;
});

export const editBook = createAsyncThunk(
  'editBook',
  async ({ id, name }: { id: string; name: string }) => {
    return await editBookName(id, name);
  },
);

export const deleteBookAction = createAsyncThunk(
  'deleteBook',
  async ({ id }: { id: string }) => await deleteBook(id),
);

export const saveBook = createAsyncThunk(
  'saveBook',
  async ({ book }: { book: Book }) =>
    await updateBookApi({ book, bookName: book.id }),
);

type BookState = {
  books: Book[];
  selectedBook: string;
  pageNumber: number;
  status: 'idle' | 'loading' | 'success' | 'failure';
  previousEdit: string;
  removedBook?: Book;
  hasUnsavedChanges: boolean;
  saveError: boolean;
  past: Page[];
  present: Page;
  future: Page[];
};

export const initialState: BookState = {
  status: 'idle',
  books: [],
  selectedBook: '',
  pageNumber: 0,
  // currentPage: defaultPage,
  previousEdit: '',
  hasUnsavedChanges: false,
  saveError: false,
  past: [],
  present: defaultPage,
  future: [],
};

export const bookSlice = createSlice({
  name: 'book',
  initialState,
  reducers: {
    changePageNumber: (state, action) => {
      state.pageNumber = action.payload;
      const book = state.books.find((book) => book.id === state.selectedBook);
      // reset undo/redo
      state.past = [];
      state.future = [];
      if (book && book.pages && book.pages.length > action.payload) {
        state.present = book.pages[action.payload];
      } else {
        state.present = defaultPage;
      }
    },
    selectBook: (state, action) => {
      state.selectedBook = action.payload;

      // reset page number and active page
      state.pageNumber = 0;
      const book = state.books.find((book) => book.id === action.payload);

      state.past = [];
      state.future = [];
      if (book && book.pages && book.pages.length) {
        state.present = book.pages[0];
      } else {
        state.present = defaultPage;
      }
    },

    // change book/page data
    updateBook: (state, action) => {
      state.books = state.books.map((book) => {
        if (book.id === state.selectedBook) {
          book = action.payload;
          if (book.pages && book.pages.length) {
            // update past
            state.past = [...state.past, state.present];

            // update
            state.present = book.pages[state.pageNumber];
            // made changes so reset future
            state.future = [];
          } else {
            state.present = defaultPage;
          }
        }
        state.hasUnsavedChanges = true;
        return book;
      });
    },

    undo: (state) => {
      const previous = state.past[state.past.length - 1];
      const newPast = state.past.slice(0, state.past.length - 1);

      state.future = [state.present, ...state.future];
      state.past = newPast;
      state.present = previous;
    },

    redo: (state) => {
      const next = state.future[0];
      const newFuture = state.future.slice(1);

      state.past = [...state.past, state.present];
      state.present = next;
      state.future = newFuture;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadBooks.fulfilled, (state, action) => {
      state.books = action.payload;
      const book = state.books.find((book) => book.id === state.selectedBook);
      state.past = [];
      state.future = [];
      if (book && book.pages && book.pages.length) {
        state.present = book.pages[0];
      } else {
        state.present = defaultPage;
      }
    });
    builder.addCase(addBook.fulfilled, (state, action) => {
      state.books.push({ ...defaultBook, id: action.payload });
    });

    // optimistic update
    builder.addCase(editBook.pending, (state, action) => {
      state.books = state.books.map((book) => {
        if (book.id === action.meta.arg.id) {
          state.previousEdit = book.name;
          book.name = action.meta.arg.name;
        }
        return book;
      });
    });

    // TODO handle edit failure
    builder.addCase(editBook.rejected, (state, action) => {
      state.books = state.books.map((book) => {
        if (book.id === action.meta.arg.id) {
          book.name = state.previousEdit;
        }
        return book;
      });
    });

    builder.addCase(deleteBookAction.pending, (state, action) => {
      const i = state.books.findIndex((book) => book.id === action.meta.arg.id);
      state.removedBook = state.books.splice(i, 1)[0];
    });
    builder.addCase(deleteBookAction.rejected, (state, action) => {
      if (state.removedBook) {
        state.books.push(state.removedBook);
      }
      state.removedBook = undefined;
    });

    builder
      .addCase(saveBook.fulfilled, (state, action) => {
        state.hasUnsavedChanges = false;
        state.saveError = false;
      })
      .addCase(saveBook.rejected, (state, action) => {
        state.saveError = true;
        state.hasUnsavedChanges = true;
      });
  },
});

export const { updateBook, selectBook, changePageNumber, undo, redo } =
  bookSlice.actions;
export const selectActiveBook = (state: RootState) => {
  return (
    state.book.books.find(({ id }) => id === state.book.selectedBook) ??
    defaultBook
  );
};
