import styled from 'styled-components';
import { theme } from '../../utils/theme';

export const TabsWrapper = styled.div`
  display: block;
  text-align: center;
  max-width: ${theme.maxWidth};
  margin: auto;
  width: 100%;

  @media (min-width: 1000px) {
    margin: auto;
  }

  button {
    width: 50%;
  }
`;
