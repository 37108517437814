import { useContext } from 'react';
import { getTileColour, palette } from '../../utils/utils';
import { ToolbarContext } from './ToolbarProvider';
import {
  Brush,
  BrushBlock,
  SelectedBrush,
  StyledToolbar,
  ToolbarWrapper,
  ToolWrapper,
} from './Toolbar.styled';
import { ToolButton } from './ToolButton';
import { redo, undo, useAppDispatch, useAppSelector } from '../../store';
import { HistoryButton } from './HistoryButton';
import { UndoIcon } from '../icons/Undo';
import { RedoIcon } from '../icons/Redo';
import { DrawIcon } from '../icons/Draw';
import { FillIcon } from '../icons/Fill';

const ToolbarButton = ({
  tile,
  setSelected,
  isSelected,
}: {
  tile: number;
  isSelected: boolean;
  setSelected: (tile: number) => void;
}) => {
  const Component = isSelected ? SelectedBrush : Brush;

  return (
    <Component
      onClick={() => setSelected(tile)}
      role="button"
      aria-selected={isSelected ? true : false}
      data-testid="toolbar-button"
    >
      <BrushBlock style={{ background: getTileColour(tile) }} />
    </Component>
  );
};

export const Toolbar = () => {
  const dispatch = useAppDispatch();

  const { selected, setSelected, mode, setMode } = useContext(ToolbarContext);
  const { past, future } = useAppSelector((state) => state.book);

  return (
    <ToolbarWrapper data-testid="toolbar">
      <ToolWrapper>
        <ToolButton onClick={() => setMode('fill')} isActive={mode === 'fill'}>
          <FillIcon /> Fill
        </ToolButton>
        <ToolButton onClick={() => setMode('draw')} isActive={mode === 'draw'}>
          <DrawIcon />
          Draw
        </ToolButton>

        <HistoryButton
          onClick={() => dispatch(undo())}
          disabled={past.length === 0}
        >
          <UndoIcon /> Undo
        </HistoryButton>
        <HistoryButton
          onClick={() => dispatch(redo())}
          disabled={future.length === 0}
        >
          <RedoIcon /> Redo
        </HistoryButton>
      </ToolWrapper>

      <StyledToolbar>
        {palette.map((_x, i) => (
          <ToolbarButton
            key={i}
            tile={i}
            setSelected={setSelected}
            isSelected={selected === i}
          />
        ))}
      </StyledToolbar>
    </ToolbarWrapper>
  );
};
