import { apiUrl } from '../constants';
import { post } from './api-service';

export const editBookName = async (bookId: string, bookName: string) => {
  try {
    return post({
      url: `${apiUrl}/book/${bookId}`,
      body: {
        name: bookName,
      },
    });
  } catch (err) {
    console.error(err);
  }
};
