import React, { PropsWithChildren, useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import EditPage from './pages/Edit/Edit';
import reportWebVitals from './reportWebVitals';
import { Login } from './pages/Login/Login';
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from 'react-router-dom';
import { ErrorPage } from './pages/Error';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Account } from './pages/Account/Account';
import { loadBooks, store, useAppDispatch } from './store';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/react';

Sentry.init({
  dsn: 'https://b916bdae473d0273917b3712b6eadfea@o4508128639516672.ingest.de.sentry.io/4508128643842128',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.browserProfilingIntegration(),
    // Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    // 'localhost',
    /^https:\/\/flipbook\.jamesbaum\.co\.uk/,
  ],
  // Set profilesSampleRate to 1.0 to profile every transaction.
  // Since profilesSampleRate is relative to tracesSampleRate,
  // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
  // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
  // results in 25% of transactions being profiled (0.5*0.5=0.25)
  profilesSampleRate: 1.0,
  // Session Replay
  // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

// always load books on first run
const App = ({ children }: PropsWithChildren) => {
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch(loadBooks());
  }, []);

  return children;
};

const router = createBrowserRouter([
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/account',
    element: <Account />,
  },
  {
    path: '/dashboard',
    element: (
      <App>
        <Dashboard />
      </App>
    ),
  },
  {
    path: '/edit/:bookName',
    element: (
      <App>
        <EditPage />
      </App>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: '/',
    element: <Navigate to="/login" replace />,
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
