import styled from 'styled-components';

export const StyledAnimation = styled.section`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
`;

export const AnimationImg = styled.img`
  max-width: 600px;
  margin-bottom: 1rem;
`;
