import styled from 'styled-components';

export const Label = styled.label`
  font-weight: bold;
  display: block;
  margin-bottom: 0.5rem;
`;

export const FormRow = styled.div`
  display: flex;
  margin-bottom: 1rem;
`;

export const Input = styled.input`
  padding: 0.5rem 0.75rem;
`;

export const FormError = styled.div`
  margin-bottom: 1.5rem;
  padding: 1.5rem 1rem;
  border: 1px solid lightcoral;
  border-radius: 3px;
  background-color: #f080803b;
`;

export const FormNotification = styled(FormError)`
  border: 1px solid green;
  background-color: #00dd2233;
`;
