import { PropsWithChildren, useContext } from 'react';
import { ToolbarContext } from './ToolbarProvider';
import { getForegroundColour, getTileColour } from '../../utils/utils';
import { ActiveTool } from './Toolbar.styled';
import { PrimaryButton } from '../Button';

export const ToolButton = ({
  onClick,
  isActive,
  children,
}: PropsWithChildren<{
  onClick: () => void;
  isActive: boolean;
}>) => {
  const { selected } = useContext(ToolbarContext);
  const ToolComponent = isActive ? ActiveTool : PrimaryButton;

  return (
    <ToolComponent
      style={{
        background: `${getTileColour(selected)}`,
        color: `${getForegroundColour(selected)}`,
      }}
      onClick={() => onClick()}
      aria-pressed={isActive}
    >
      {children}
    </ToolComponent>
  );
};
