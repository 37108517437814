import { apiUrl } from '../constants';
import { Book, RawBook } from '../types';
import { get } from './api-service';
import { mapBook } from './mapBook';

export async function getAllBooks(): Promise<Book[]> {
  try {
    const books: RawBook[] = await get({ url: `${apiUrl}/book/` });
    return books.map(mapBook);
  } catch (err) {
    console.error(`Error fetching books: ${err}`);
    return [];
  }
}
