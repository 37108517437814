import styled from 'styled-components';
import { theme } from '../../utils/theme';

export const StyledWrapper = styled.div`
  display: flex;
  flex-grow: 2;
`;

export const NavWrapper = styled.div`
  margin: 0 1rem 1rem auto;
  max-width: ${theme.maxWidth};
`;

export const StyledEdit = styled.div`
  @media (min-height: 800px) {
    height: 100vh;
  }
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const EditContent = styled.div`
  width: 100%;
  text-align: center;
`;
