import { changePageNumber, useAppDispatch } from '../../store';
import { StyledLoadButton } from './LoadButton.styled';

export const LoadButton = ({
  pageNumber,
  selected,
  preview,
}: {
  pageNumber: number;
  selected: boolean;
  preview: string;
}) => {
  const dispatch = useAppDispatch();

  return (
    <StyledLoadButton
      data-testid="level-button"
      className={`${selected ? 'level--active' : ''}`}
      aria-selected={selected ? true : false}
      onClick={() => dispatch(changePageNumber(pageNumber))}
    >
      {preview && <img src={preview} />}
      {`Page ${Number(pageNumber) + 1}`}
    </StyledLoadButton>
  );
};
