import { useEffect, useState } from 'react';
import { AnimationImg, StyledAnimation } from './Animation.styled';
import { selectActiveBook } from '../../store';
import { useSelector } from 'react-redux';
import { PrimaryButton } from '../Button';
import { createGif } from '../../utils/encode';

export const Animation = () => {
  const book = useSelector(selectActiveBook);

  const [index, setIndex] = useState(0);
  const [frame, setFrame] = useState('');
  const [speed, setSpeed] = useState(800);

  useEffect(() => {
    if (!book.pages || book.pages.length === 0) {
      return;
    }

    setFrame(book.pages![index].hash);

    const timer = setInterval(() => {
      setIndex((index + 1) % book.pages!.length);
      setFrame(book.pages![index].hash);
    }, speed);
    return () => clearInterval(timer);
  }, [book, index, frame, speed]);

  return (
    <StyledAnimation data-testid="preview-animation">
      <PrimaryButton onClick={() => createGif(book.pages ?? [], speed)}>
        Create gif
      </PrimaryButton>

      <AnimationImg data-testid="animation-image" src={frame} />
      <label>
        <strong>Speed</strong> Faster{' '}
        <input
          type="range"
          value={speed}
          max={1200}
          min={300}
          onChange={(e) => setSpeed(Number(e.target.value))}
        />{' '}
        Slower
      </label>
    </StyledAnimation>
  );
};
