import { apiUrl } from '../constants';
import { Book } from '../types';
import { post } from './api-service';

export const updateBook = async ({
  book,
  bookName,
}: {
  book: Book;
  bookName: string;
}) => {
  return post({
    url: `${apiUrl}/book/save/${bookName}`,
    body: book,
  });
};
