import styled from 'styled-components';
import { theme } from '../../utils/theme';

export const StyledLoadButton = styled.button`
  display: block;
  border: none;
  background-color: ${theme.colour.background};
  font-size: 1rem;
  border-radius: ${theme.borderRadius};
  padding: 1rem 1rem 0.5rem;
  cursor: pointer;
  border-bottom: 3px solid ${theme.colour.border};

  &:hover {
    background-color: #ffc433;
  }

  &.level--active {
    background-color: ${theme.colour.primary};
  }

  img {
    display: block;
    margin-bottom: 0.5rem;
    height: 100px;
  }
`;
