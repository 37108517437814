import styled from 'styled-components';
import { theme } from '../../utils/theme';

export const StyledSave = styled.header`
  align-items: center;
  padding: 0 1rem 0;
  margin: 0 auto;
  height: 40px;
  display: flex;
  justify-content: space-between;
  max-width: ${theme.maxWidth};
`;

export const UnsavedChanges = styled.span`
  display: inline-block;
  margin-right: 1rem;
`;
