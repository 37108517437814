import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { bookSlice } from './bookReducer';

const rootReducer = combineReducers({
  book: bookSlice.reducer,
});

export const store = configureStore({
  reducer: rootReducer,
});

export function setupStore(preloadedState?: Partial<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export type AppStore = typeof store;
export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = AppStore['dispatch'];

// typed hooks
export const useAppDispatch = useDispatch.withTypes<AppDispatch>();
export const useAppSelector = useSelector.withTypes<RootState>();
export const useAppStore = useStore.withTypes<AppStore>();
