import { PropsWithChildren } from 'react';
import styled from 'styled-components';
import { theme } from '../utils/theme';

const Main = styled.main`
  max-width: ${theme.maxWidth};
  margin: auto;
`;

export const PageLayout = ({ children }: PropsWithChildren) => {
  return <Main>{children}</Main>;
};
