import styled from 'styled-components';

export const StyledList = styled.ul`
  display: flex;
  list-style-type: none;
  margin: 0;
  padding: 2rem;
  background: beige;
  overflow-y: scroll;
`;

export const StyledItem = styled.li`
  display: flex;
  padding: 0 1rem 0;
`;

export const LevelControls = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding-left: 0.5rem;
`;
